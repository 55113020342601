.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px); /* Floutage de l'arrière-plan */
    background-color: rgba(0, 0, 0, 0.5); /* Assombrit le fond */
    z-index: 9999; /* Assurez-vous que l'overlay soit au-dessus de tout */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay-content {
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 40px;
    border-radius: 10px;
  }
  
  .overlay-content h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .overlay-content p {
    font-size: 18px;
  }