
.tb{
   font-family: Arial, Helvetica, sans-serif; 
   box-sizing: border-box;
   font-size: 14px;
   width: 100%;
   background-color: transparent;
   margin: 0;
}
.ct{
    width: 600;
    display: block !important ;
    max-width: 600px !important;
    clear: both !important ;
}
.table1{
   width: 100%;
   border:1px solid rgba(130, 134, 156, 0.15);
   background-color:transparent; 
}
.td1{
    padding: 20px;
    border-radius: 0;
    text-align: center;
}
.td2{
    padding: 20px;
}
.bgMarine{
    background-color: #212f56;
    color: white;
}
.td3{
    font-size: 14px;
    text-decoration: none;
    line-height: 2em;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    display: block;
    border-radius: 5px ;
    text-transform: capitalize;
    border: none;
    padding: 10px 20px;
}
.td4{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding: 0 0 20px;
}
.td5{
    padding: 15px;
    border-radius: 0;
    text-align: center;
}
.tr1{
    height: 60px;
}
.image1{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 60px;
}
.strong1{
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    font-size: 14px;
    margin: 0;
}