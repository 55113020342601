@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
body {
  margin: 0;
  font-family:'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'
    sans-serif;
}

code {
  font-family: 'inter',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-item{
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.modal-header{
  background-color:#EBEFF5 ;
}
.modal-title{
  color: #334155;
}
.dhx_cal_container {
  overflow: visible !important;
}
