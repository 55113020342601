.contenuRetard{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
    .formRetard{
        width: 50%;
        border: solid  rgb(252, 252, 252) 2px ;
        border-radius: 1vw;
        background-color: rgb(252, 252, 252);
        padding-bottom: 2vw;
        border: 1px solid #E2E8F0;
        box-shadow:0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08);    
        .headerGantt{
            background-color:#EBEFF5;
            border: solid #EBEFF5 2px;
            font-weight: bold;
            font-style: italic;
            border-top-left-radius: 13px;
            border-top-right-radius: 13px;
            margin-bottom: 1vw;
            padding: 22px 12px;
            h1{
                color:#334155;
            }
            a{
                font-size: 1vw;
            }
            a:hover{
                color:#4F46E5;
            }
        }
    }
}
.hideLink{
    display: none;
}
.showLink{
    display: block;
}