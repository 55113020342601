.cibleDestinataire{
  .tabulator-row .tabulator-cell{
    overflow: hidden !important ;
  }
  .tabulator{
    overflow: hidden !important;
  }
  .tabulator-tableholder{
    overflow-x: scroll !important;
  }
}